body {
  box-sizing: border-box;
  margin: 0; 
}

h1, h2, h3, h4, h5, h6, p, input, li, span, label {
  color: #FFFFFF;
  font-family: 'Poppins', sans-serif;
  margin: 0;
}

select {
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  margin: 0;
}

h1 {
  color: #FFFFFF;
  font-size: 48px;
}

h2 {
  font-size: 24px;
}

h3 {
  font-size: 16px;
}

h4 {
  font-size: 14px;
}

h5 {
  font-size: 12px;
}

h6 {
  font-size: 10px;
}

.dropshadow {
  filter: drop-shadow(2px 2px rgba(0,0,0,0.15));
}

.panel-bg {
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  background: rgba(255, 255, 255, 0.25);
}

.widget-bg {
  align-items: center;
  background: rgba(255,255,255,0.1);
  border: 1px solid rgba(255,255,255,0.25);
  border-radius: 8px;
  box-sizing: border-box;
  display: flex;
  padding: 16px;
  position: relative;
  width: 100%;
}

li {
  font-size: 12px;
  font-weight: 500;
}